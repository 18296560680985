<template>

    <div class="wrapContentIndex wrapContentStatement">
        <div class="ListGame ListGame-statement">
           <div class="wrapContentIndexTitle">
                <button class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
               <h3>รายงานการเงิน</h3>
                <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber" :goHome="is_go_home"></MenuBar>
            </div>

            <!--        <MenuBar backRouteName="Profile"></MenuBar>-->
            <div class="box-img-top-profile hide">
                <div class="box-user-detail">
                    <strong>รายงาน</strong>
                </div>
            </div>
            <div class="wrapContentIndexInfo">
                <div class="contentFlexIndex">
                    <div class="contentFlexIndexRight">
                        <div class="container">
                            <div class="content-page">
                                <div class="top-filter-statement">
                                    <div class="top-filter-statement-info">
                                        <span class="topic-filter">เลือกช่วงเวลา</span>
                                        <ul class="bx-btn-filter-statement">
                                            <li>
                                                <div @click="selectDate('TD')"
                                                     class="btn btn-sm"
                                                     :class="{'active': interval === 'TD'}">วันนี้</div>
                                            </li>
                                            <li>
                                                <div @click="selectDate('YD')"
                                                     class="btn btn-sm"
                                                     :class="{'active': interval === 'YD'}">เมื่อวาน</div>
                                            </li>
                                        </ul>
                                        <ul class="bx-btn-filter-statement-date">
                                            <li>
                                                <div class="groups-bx-date">
                                                    <span>วันที่เริ่ม</span>
                                                    <date-picker
                                                            v-model="start_date"
                                                            format="DD/MM/YYYY"
                                                            value-type="format"
                                                            type="date" placeholder="วันที่เริ่ม"
                                                            @change="selectDateRange"></date-picker>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="groups-bx-date">
                                                    <span>วันที่สิ้นสุด</span>
                                                    <date-picker
                                                            v-model="end_date"
                                                            format="DD/MM/YYYY"
                                                            value-type="format"
                                                            type="date" placeholder="วันที่สิ้นสุด"
                                                            @change="selectDateRange"></date-picker>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <button @click="submit" type="submit" class="btn btn-search-filter">ค้นหา</button>

                                </div>
                                <!--            <h6>ผลการค้นหา : <small>22/07/2022 ถึง 23/07/2022</small></h6>-->
                                <div class="content-page-statement">
                                    <div class="content-page-statement-top">
                                        <ul>
                                            <li>Datetime</li>
                                            <li>Currency</li>
                                            <li>Type</li>
                                            <li>Title</li>
                                            <li>Before</li>
                                            <li>Income</li>
                                            <li>Outcome</li>
                                            <li>After</li>
                                            <li>Description</li>
                                        </ul>
                                    </div>
                                    <div class="content-page-statement-info">
                                        <div v-if="list.length > 0">
                                            <ul v-for="(item, key) of list" :key="key">
                                                <li>
                                                    <span>{{parseInt(item.statement_time) | dateTimeFormat}}</span>
                                                </li>
                                                <li>
                                                    <span>{{item.symbol}}</span>
                                                </li>
                                                <li>
                                                    <span>{{item.statement_type | typeDisplay}}</span>
                                                </li>
                                                <li>
                                                    <span>{{item.title}}</span>
                                                </li>
                                                <li>
                                                    <span>{{item[`${item.symbol}_before`] | floatFormat}}{{item.symbol=='DIAMOND'?'K':''}}</span>
                                                </li>
                                                <li>
                                                    <span>{{item[`${item.symbol}_income`] | floatFormat}}{{item.symbol=='DIAMOND'?'K':''}}</span>
                                                </li>
                                                <li>
                                                    <span>{{item[`${item.symbol}_outcome`] | floatFormat}}{{item.symbol=='DIAMOND'?'K':''}}</span>
                                                </li>
                                                <li>
                                                    <span>{{item[`${item.symbol}_after`] | floatFormat}}{{item.symbol=='DIAMOND'?'K':''}}</span>
                                                </li>
                                                <li>
                                                    <span></span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-else>
                                            <div style="padding: 10px 0 0 10px;">ไม่มีข้อมูล</div>
                                        </div>
                                        <div class="statement-page-pagination">
                                            <el-pagination
                                                    v-if="pageCount > 1"
                                                    @current-change="(current) => {page = current}"
                                                    background
                                                    :page-size="limit"
                                                    layout="prev, pager, next"
                                                    :total="pageCount * limit">
                                            </el-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navButtonMobile">
                    <MenuNav  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
                </div>
            </div>

        </div>
    </div>

</template>
<script>

import MenuBar from '@/components/MenuBar'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import MenuNav from '@/components/MenuNav'

const STATEMENT_MAP = {
    0: 'trade',
    1: 'deposit',
    2: 'withdraw',
    3: 'crypto deposit',
    4: 'crypto withdraw',
    5: 'exchange',
    6: 'game',
    7: 'game2',
    8: 'bonus',
    9: 'user_deposit',
    10: 'user_withdraw',
    11: 'baccarat',
    12: 'voucher',
    13: 'voucher',
    14: 'football',
    15: 'daily_mission',
    16: 'level up',
    17: 'air drop',
    18: 'cash back',
    19: 'free bonus',
    20: 'referral bonus',
    21: 'Reward Event',
    22: 'Slot',
    23: 'lotto',
    24: 'user Reward',
    25: 'baccarat',
    26: 'sicbo',
    27: 'bean',
    28: 'Coin',
    29: 'Exchange Coin',
    30: 'purchase packet',
    31: 'dragontiger',
    34: 'baccarat2',
    35: 'daily_login'
}

export default {
    name: 'statement',
    components: {
        MenuBar,
        DatePicker,
        MenuNav,
    },
    data() {
        return {
            is_go_home: '',
            showChatWithAdminNumber:0,
            showDepositWithdrawNumber:0,
            menuNumber :0,
            searchBy: 'interval', // 'interval', 'date_range'
            page: 1,
            limit: 100,
            interval: 'TD', // 'TD', 'YD'
            start_date: null,
            end_date: null,
            list: [],
            pageCount: 0,

            isHideProvider: false,
            isHideListGame: true,
            isHideListGameContent: false,
            currency_list :[
                'THB','DIAMOND',
            ]
        }
    },
    computed: {
        pes_profile() {
            return store.state.pes_profile
        },
        token() {
            return this.pes_profile.token
        },
        member_id() {
            return this.pes_profile.user_id
        },
        mode (){
            return store.state.pes_profile.mode;
        }
    },
    watch: {
        page(newVal) {
            if(newVal) {
                this.loadData()
            }
        },
    },
    filters: {
        typeDisplay(raw) {
            return STATEMENT_MAP[raw]
        },
    },
    methods: {
        goHome() {
                this.is_go_home='Index';
                let self = this;
                setTimeout(()=>{
                    self.is_go_home='';
                },1000)
                
        },
        showChatWithAdmin(){
                this.showChatWithAdminNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showChatWithAdminNumber=0;
                },100);
                
            },
            showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

        showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
        },
        OpenListProvider() {
            this.isHideProvider = true;
        },
        ClosePopupProvider() {
            this.isHideProvider = false;
        },
        OpenListAllGame(){
            this.isHideListGame = false;
            this.isHideListGameContent = true;
        },
        CloseListAllGame() {
            this.isHideListGame = true;
            this.isHideListGameContent = false;
        },

        toIndexPopularPage() {
            this.$router.push({name: 'IndexPopular'})
        },
        toIndexCasinoPage() {
            this.$router.push({name: 'IndexCasino'})
        },
        toIndexSlotPage() {
            this.$router.push({name: 'IndexSlot'})
        },
        toIndexFishingPage() {
            this.$router.push({name: 'IndexFishing'})
        },
        toIndexSportPage() {
            this.$router.push({name: 'IndexSport'})
        },
        toIndexLottoPage() {
            this.$router.push({name: 'IndexLotto'})
        },
        toIndexUnboxPage() {
            this.$router.push({name: 'IndexUnbox'})
        },

        selectDate(interval) {
            this.searchBy = 'interval'
            this.interval = interval
            this.loadData()
        },
        selectDateRange() {
            this.searchBy = 'date_range'
        },
        submit() {
            this.loadData()
        },
        async loadData() {

            const loading = this.$loading()

            const data = {
                token: this.token,
                page: this.page,
                limit: this.limit,
                member_id: this.member_id,
                symbol : this.currency_list[this.mode],
            }

            if(this.searchBy === 'date_range') {
                Object.assign(data, {
                    start_date: this.start_date,
                    end_date: this.end_date,
                })
            } else {
                Object.assign(data, {
                    interval: this.interval,
                })
            }

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/pes/get-user-statement`,
                data: data
            })
            if(res.status === 200 && !res.data.data.code) {
                this.list = res.data.data.list
                this.pageCount = res.data.data.count
            } else {
                console.log('loadData err', res.data.data.msg)
                this.$bvToast.toast('ไม่สามารถโหลดรายงานได้ กรุณาลองอีกครั้ง', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }

            loading.close()
        },
    },
    mounted() {
        this.loadData()
    }
}
</script>
<style src="@/assets/css/profile.css" scoped></style>
<style>

    .ListGame .MenuBar.box-all-games{display: none!important}
    .ListGame-statement span.box-txt-back:before{
        content: '\e88a';
        font: 25px "Material Icons";
        margin-right: 5px;
    }
    @media (orientation: landscape) {
        /*.ListGame-statement .wrap-menu-top {display: none}*/

        /*.ListGame-statement .box-nav-menu{padding: 10px;}*/
        /*.ListGame-statement span.box-txt-back {height: 48px;left: -14px;}*/
        /*.ListGame-statement span.box-txt-back:before {font: 800 18px/48px "Font Awesome\ 5 Free";}*/
    }
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape){
        /*.ListGame-statement .wrap-menu-top{*/
        /*    display: block;*/
        /*}*/
    }
    .statement-page-pagination .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #753189;
        color: #FFF;
    }
</style>
<style scoped>
    .ListGame{
        background-image: linear-gradient(
                135deg, #F372D4 10%, #c765f0 100%);
    }
    .wrap-menu-top {
        padding: 10px 5px 10px 5px;
        background-image: none;
    }


    .content-page {
        background-color: #fff;
        border-radius: 20px 20px 0 0;
    }
    @media (orientation: landscape) {
        .top-filter-statement{
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
        }
        button.btn.btn-search-filter{
            width: auto;
        }
        .ListGame-statement h6{
            padding: 10px 0;
        }
        .content-page-statement-info{
            /*height: calc(100vh - 178px);*/
            height: 100vh;
            overflow-y: auto;
        }
        .ListGame-statement .content-page {margin: 0!important; border-radius: 0}
    }

    .mx-datepicker {
        width: 100px!important;
    }
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape){
        .mx-datepicker {
            width: 210px!important;
        }
        .mx-input{height: 40px;}
        button.btn.btn-search-filter{
            margin: 15px 0 0;
        }
    }
</style>